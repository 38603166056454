import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-4 pb-6 text-color font-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ProgressSpinner, { strokeWidth: "5" }),
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.text), 1)
  ]))
}